import Web3 from "web3";
import CONTRACT_ABI from "./../../Components/Assets/ABI/ContractABI.json";
import { CONTRACT_ADDRESS } from "../../constants/constants";
import { toast } from "../../Components/Ui/Toast/Toast";

const callWeb3 = async () => {
  const { ethereum } = window;
  let web3ObjectCall;

  if (ethereum && ethereum.isMetaMask) {
    web3ObjectCall = new Web3(ethereum);
    console.log("web3ObjectCall", web3ObjectCall);
    const networkId = await web3ObjectCall.eth.net.getId();

    // if (networkId !== 80002) {
    //   toast.error("Please switch MetaMask network to Testnet Amoy");
    //   throw new Error("Please switch MetaMask network to Testnet Amoy");
    // }
    if (networkId !== 137) {
      toast.error("Please switch MetaMask network to Polygon Mainnet!");
      throw new Error("Please switch MetaMask network to Polygon Mainnet");
    }
    window.ethereum.enable(); // Request account access if needed
    return web3ObjectCall;
  }
  if (window.web3) {
    web3ObjectCall = new Web3(window.web3.currentProvider);
    console.log("web3ObjectCall", web3ObjectCall);

    return web3ObjectCall;
  }

  toast.error("MetaMask not found. Please install it!");
  throw new Error("MetaMask is required.");
};

// export const convertToDecimal = (value, decimal) => {
//   // Convert the value to decimal
//   return value / Math.pow(10, decimal);
// };

export const callContract = async () => {
  const web3Object = await callWeb3();
  const contractObject = await new web3Object.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  return contractObject;
};

export const addProposalMethod = async (data, addProposalResponse) => {
  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    console.log("accounts", accounts);
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    console.log("owner", owner);

    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }

    const contract = await callContract();
    // console.log("contract", contract);
    const tokenName = data?.token_name;
    const tokenDecimals = 18;
    const tokenSymbol = data?.symbol;

    const TokenDetails = {
      owner: CONTRACT_ADDRESS,
      name: tokenName,
      decimals: tokenDecimals,
      symbol: tokenSymbol,
      irs: "0x0000000000000000000000000000000000000000",
      ONCHAINID: "0x0000000000000000000000000000000000000000",
      irAgents: [CONTRACT_ADDRESS],
      tokenAgents: [CONTRACT_ADDRESS],
      complianceModules: [],
      complianceSettings: [],
    };

    const ClaimDetails = {
      claimTopics: [],
      issuers: [],
      issuerClaims: [],
    };

    const name = data?.title;

    const salt = addProposalResponse;
    // Date.now() + Math.random().toString(36).substring(2);
    const totalSupply = (
      data?.token_quantity * Math.pow(10, tokenDecimals)
    ).toString();
    // console.log(
    //   "name",
    //   name,
    //   "salt",
    //   salt,
    //   "totalSupply",
    //   totalSupply,
    //   "TokenDetails",
    //   TokenDetails,
    //   "ClaimDetails",
    //   ClaimDetails
    // );

    const currentGasPrice = await web3Object.eth.getGasPrice(); //(currentGasPrice*1.5)
    const gasPrice = web3Object.utils.fromWei(
      currentGasPrice.toString(),
      "gwei"
    );
    const higherGasPrice = gasPrice * 1.5;
    const FinalGasPrice = web3Object.utils.toWei(
      higherGasPrice.toString(),
      "gwei"
    );

    // console.log("currentGasPrice", currentGasPrice);
    // console.log("FinalGasPrice", FinalGasPrice);

    const gasEstimation = await contract.methods
      .deployProject(name, salt, totalSupply, TokenDetails, ClaimDetails)
      .estimateGas({ from: owner });

    console.log("gasEstimation", gasEstimation);

    const deployTransaction = await contract.methods
      .deployProject(name, salt, totalSupply, TokenDetails, ClaimDetails)
      .send({
        from: owner,
        gas: gasEstimation,
        gasPrice: FinalGasPrice,
      });

    console.log("Deployed transaction receipt:", deployTransaction);

    if (deployTransaction.events) {
      console.log("Inside events");
      const { events } = deployTransaction;
      const deployedEvent = events.deployed;

      if (deployedEvent) {
        console.log("Inside event deployed");

        const token = deployedEvent.returnValues[0].token;
        const salt = deployedEvent.returnValues.salt;
        const project_id = deployedEvent.returnValues.projectId;

        console.log(
          "Deployed Unique ID && token address:",
          salt,
          token,
          project_id
        );
        return { salt, token, project_id }; // Return the valuesl
      } else {
        console.error("Deployed event not found in transaction receipt");
        return null;
      }
    } else {
      console.error("Events property not found in transaction receipt");
      return null;
    }
  } catch (error) {
    if (error?.message?.includes("Transaction has been reverted by the EVM")) {
      toast.error("Transaction has been reverted by the EVM");
    } else if (
      error?.message?.includes(
        "MetaMask Tx Signature: User denied transaction signature"
      )
    ) {
      toast.error("User denied transaction signature");
      return {};
    } else if (
      error?.message?.includes(
        "execution reverted: Ownable: caller is not the owner"
      )
    ) {
      toast.error("Caller is not the owner"); // Display specific error message
    } else {
      toast.error("Something went wrong!"); // Default error message
      console.log(error);
    }
  }
};

export const getPastEventLogsForDeployedProposal = async () => {
  const web3Object = await callWeb3();
  const accounts = await web3Object.eth.getAccounts();
  // const gasPrice = await web3Object.eth.getGasPrice();
  // const owner = accounts[0];
  // if (owner == undefined) {
  //   toast.error("Please connect to metamask and try again!");
  // }
  // console.log("owner", owner);

  const contract = await callContract();
  const latestBlock = await web3Object.eth.getBlockNumber();
  console.log("latestBlock", latestBlock);
  const fromBlock = latestBlock - 50000;
  const options = {
    fromBlock: fromBlock, // Start block (optional)
    toBlock: latestBlock, // End block (optional)
    filter: {
      // Event filter criteria (optional)
      // eventName: value,
      // Additional filter criteria...
    },
  };
  // Retrieve past events
  const events = await contract.getPastEvents("deployed", options);
  console.log(events);
};

export const addAgentForProposal = async (
  selectedTokenAddress,
  agentAddress
) => {
  console.log("data for agent method", selectedTokenAddress, agentAddress);

  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const currentGasPrice = await web3Object.eth.getGasPrice(); //(currentGasPrice*1.5)
    const gasPrice = web3Object.utils.fromWei(
      currentGasPrice.toString(),
      "gwei"
    );
    const higherGasPrice = gasPrice * 1.5;
    const FinalGasPrice = web3Object.utils.toWei(
      higherGasPrice.toString(),
      "gwei"
    );
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);

    const contract = await callContract();

    const _agent = agentAddress;
    const _token = selectedTokenAddress;
    const gasEstimation = await contract.methods
      .addAgentOnToken(_agent, _token)
      .estimateGas({ from: owner });
    const result = await contract.methods.addAgentOnToken(_agent, _token).send({
      from: owner,
      gas: gasEstimation, // Use the estimated gas value
      gasPrice: FinalGasPrice,
    });
    console.log("Deployed Agent For Proposal:", result);
    if (result.events) {
      console.log("Inside events");
      const { events } = result;
      const eventToBeCalled = events.AgentAdded;

      if (eventToBeCalled) {
        console.log("Inside eventToBeCalled");

        const proposalAgentAddress = eventToBeCalled.returnValues._agent;
        console.log("Deployed agentAddress :", proposalAgentAddress);
        return { proposalAgentAddress }; // Return the values
      } else {
        console.error("Deployed event not found in transaction receipt");
        return null;
      }
    } else {
      console.error("Events property not found in transaction receipt");
      return null;
    }
  } catch (error) {
    toast.error(error.message);
    console.error("Error in addAgentForProposal:", error.message);
  }
};

export const mintTokenForAddress = async (data) => {
  console.log("data in mintTokens contract method", data);

  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);
    const tokenDecimals = 18;

    const contract = await callContract();
    const currentGasPrice = await web3Object.eth.getGasPrice(); //(currentGasPrice*1.5)
    const gasPrice = web3Object.utils.fromWei(
      currentGasPrice.toString(),
      "gwei"
    );
    const higherGasPrice = gasPrice * 1.5;
    const FinalGasPrice = web3Object.utils.toWei(
      higherGasPrice.toString(),
      "gwei"
    );
    const user = data?.userAddress;
    const token = data?.tokenAddress;
    const _amount = (data?.amount * Math.pow(10, tokenDecimals)).toString();
    console.log(
      "MINTING CONTRACT METHOD DATA, userAddress, tokenaddress, amount",
      user,
      token,
      _amount
    );
    const gasEstimation = await contract.methods
      .mintTokens(user, token, _amount)
      .estimateGas({ from: owner });

    const tx = contract.methods.mintTokens(user, token, _amount).send({
      from: owner,
      gas: gasEstimation,
      gasPrice: FinalGasPrice,
    });

    return new Promise((resolve, reject) => {
      tx.on("transactionHash", (txHash) => {
        console.log("Transaction hash:", txHash);
        resolve(txHash); // Resolve the promise with the hash
      })
        .on("receipt", (receipt) => {
          console.log("Transaction receipt:", receipt);
        })
        .on("error", (error) => {
          console.error("Transaction error:", error);
          reject(error); // Reject the promise on error
        });

      // Await the transaction result
      tx.then((result) => {
        console.log("Result of Mint token for a user:", result);
      }).catch((err) => {
        console.error("Transaction failed:", err);
        reject(err);
      });
    });
    // if (result.events) {
    //   console.log("Inside events");
    //   const { events } = result;
    //   console.log("EVENTS", events);
    //   const mintedEvent = events.tokenMint;

    //   if (mintedEvent) {
    //     console.log("Inside event deployed");

    //     const userAddress = mintedEvent.returnValues.user;
    //     const amount = mintedEvent.returnValues.amount;
    //     const txHash = mintedEvent.transactionHash;

    //     console.log(
    //       "Minted token amount, userAddress,txhash :",
    //       amount,
    //       userAddress,
    //       txHash
    //     );
    //     return { amount, userAddress, txHash }; // Return the valuesl
    //   } else {
    //     toast.error("TokenMint event not found in transaction receipt");
    //     console.error("tokenMint event not found in transaction receipt");
    //     return null;
    //   }
    // } else {
    //   console.error("Events property not found in transaction receipt");
    //   return null;
    // }
  } catch (error) {
    handleContractError(error);
  }
};

export const batchMintingAll = async (data) => {
  console.log("data", data);

  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);
    const currentGasPrice = await web3Object.eth.getGasPrice(); //(currentGasPrice*1.5)
    const gasPrice = web3Object.utils.fromWei(
      currentGasPrice.toString(),
      "gwei"
    );
    const higherGasPrice = gasPrice * 1.5;
    const FinalGasPrice = web3Object.utils.toWei(
      higherGasPrice.toString(),
      "gwei"
    );
    const contract = await callContract();

    const _toList = data?.data?.userAddresses;
    const _token = data?.data?.tokenAddress;
    const _amounts = data?.data?.amounts;
    console.log("MINTING CONTRACT METHOD DATA", _toList, _amounts, _token);
    const gasEstimation = await contract.methods
      .batchMintTokens(_toList, _amounts, _token)
      .estimateGas({ from: owner });

    const tx = contract.methods
      .batchMintTokens(_toList, _amounts, _token)
      .send({
        from: owner,
        gas: gasEstimation,
        gasPrice: FinalGasPrice,
      });

    return new Promise((resolve, reject) => {
      tx.on("transactionHash", (txHash) => {
        console.log("Transaction hash:", txHash);
        resolve(txHash); // Resolve the promise with the hash
      })
        .on("receipt", (receipt) => {
          console.log("Transaction receipt:", receipt);
        })
        .on("error", (error) => {
          console.error("Transaction error:", error);
          reject(error); // Reject the promise on error
        });

      // Await the transaction result
      tx.then((result) => {
        console.log("Result of batchminting:", result);
      }).catch((err) => {
        console.error("Transaction failed:", err);
        reject(err);
      });
    });
    // if (result.events) {
    //   console.log("Inside events");
    //   const { events } = result;
    //   console.log("EVENTS", events);
    //   const mintedEvent = events.BatchMint;

    //   if (mintedEvent) {
    //     console.log("Inside event deployed");

    //     const walletAddresses = mintedEvent.returnValues.to;
    //     const mintedAmounts = mintedEvent.returnValues.amount;
    //     const txHash = mintedEvent.transactionHash;
    //     console.log(
    //       "Minted token amount, userAddress :",
    //       mintedAmounts,
    //       walletAddresses,
    //       txHash
    //     );
    //     return { mintedAmounts, walletAddresses, txHash }; // Return the valuesl
    //   } else {
    //     toast.error("TokenMint event not found in transaction receipt");
    //     console.error("tokenMint event not found in transaction receipt");
    //     return null;
    //   }
    // } else {
    //   console.error("Events property not found in transaction receipt");
    //   return null;
    // }
  } catch (error) {
    handleContractError(error);
  }
};

export const secondaryTokensTransfer = async (data) => {
  console.log("data in mintTokens contract method", data);

  try {
    const web3Object = await callWeb3();
    const accounts = await web3Object.eth.getAccounts();
    // const gasPrice = await web3Object.eth.getGasPrice();
    const owner = accounts[0];
    if (owner == undefined) {
      toast.error("Please connect to metamask and try again!");
    }
    console.log("owner", owner);
    const tokenDecimals = 18;

    const contract = await callContract();

    const _user = data?.userAddress;
    const _token = data?.tokenAddress;
    const _amount = (data?.amount * Math.pow(10, tokenDecimals)).toString();

    // console.log(
    //   "DATA IN TOKEN TRANSFER, userAddress",
    //   _user,
    //   "tokenaddress",
    //   _token,
    //   "amount",
    //   _amount
    // );

    const currentGasPrice = await web3Object.eth.getGasPrice(); //(currentGasPrice*1.5)
    const gasPrice = web3Object.utils.fromWei(
      currentGasPrice.toString(),
      "gwei"
    );
    const higherGasPrice = gasPrice * 1.5;
    const FinalGasPrice = web3Object.utils.toWei(
      higherGasPrice.toString(),
      "gwei"
    );
    const gasEstimation = await contract.methods
      .adminTokensTransfer(_amount, _token, _user)
      .estimateGas({ from: owner });

    console.log("gasEstimation", gasEstimation);
    const tx = contract.methods
      .adminTokensTransfer(_amount, _token, _user)
      .send({
        from: owner,
        gas: gasEstimation,
        gasPrice: FinalGasPrice,
      });

    return new Promise((resolve, reject) => {
      tx.on("transactionHash", (txHash) => {
        console.log("Transaction hash:", txHash);
        resolve(txHash); // Resolve the promise with the hash
      })
        .on("receipt", (receipt) => {
          console.log("Transaction receipt:", receipt);
        })
        .on("error", (error) => {
          console.error("Transaction error:", error);
          reject(error); // Reject the promise on error
        });

      // Await the transaction result
      tx.then((result) => {
        console.log("Result of transfer token:", result);
      }).catch((err) => {
        console.error("Transaction failed:", err);
        reject(err);
      });
    });

    // console.log("Transaction Hash:", result?.transactionHash);

    // if (result) {
    //   console.log("Inside events");
    //   const { events } = result;
    //   console.log("EVENTS", events);

    //   const transferEvent = events.AdminTokensTranfer;

    //   if (transferEvent) {
    //     console.log("Inside event AdminTokensTranfer");

    //     const txHash = transferEvent.transactionHash;

    //     console.log("Minted token txhash :", txHash);
    //     return { txHash }; // Return the valuesl
    //   } else {
    //     toast.error(
    //       "AdminTokensTranfer event not found in transaction receipt"
    //     );
    //     console.error(
    //       "AdminTokensTranfer event not found in transaction receipt"
    //     );
    //     return null;
    //   }
    // } else {
    //   console.error("Events property not found in transaction receipt");
    //   return null;
    // }
  } catch (error) {
    handleContractError(error);
  }
};

function handleContractError(error) {
  if (
    error?.message?.includes(
      "execution reverted: Contract doesn't have enough balance."
    )
  ) {
    toast.error("Contract doesn't have enough balance!"); // Display specific error message
  } else if (
    error?.message?.includes(
      "execution reverted: Ownable: caller is not the owner"
    )
  ) {
    toast.error("User is not the owner!"); // Display specific error message
  } else if (
    error?.message?.includes("execution reverted: Compliance not followed")
  ) {
    toast.error("Compliance not followed!"); // Display specific error message
  } else if (
    error?.message?.includes("execution reverted: Identity is not verified.")
  ) {
    toast.error("Identity not verified!"); // Display specific error message
  } else if (
    error?.message?.includes(
      "execution reverted: TransferHelper::safeTransfer: transfer failed"
    )
  ) {
    toast.error("Address not whitelisted!"); // Display specific error message
  } else {
    toast.error(error.message); // Default error message
  }
  console.error("Error in contract method execution:", error.message);
}

//execution reverted: TransferHelper::safeTransfer: transfer failed
